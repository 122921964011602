import { format } from 'date-fns';
import { ca } from 'date-fns/locale';


import {
  DateField, FunctionField, useNotify, Button
} from 'react-admin';

import LinearProgress from '@mui/material/LinearProgress';
import Box from '@material-ui/core/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Typography from '@mui/material/Typography';

import { useTranslate  } from '../common/language';

export const setActivePermissionData = (permission_id) => {
        
    var permissions = JSON.parse(localStorage.permissions);
    
    let indexPermission = 0;
    for (let i=0;i<permissions.length;i++)
    {
        if (permissions[i].id===permission_id)
        {
            indexPermission = i;
        }                
    }
                        
    if (permissions.length > 0)
    {
        localStorage.setItem('associations_id', permissions[indexPermission].associations_id );
        localStorage.setItem('associations_name', permissions[indexPermission].associations_name );  
        localStorage.setItem('permissions_groups', permissions[indexPermission].permissions_groups );
        localStorage.setItem('members_group_id', permissions[indexPermission].members_group_id );
        localStorage.setItem('associations_logo_doc', permissions[indexPermission].associations_logo_doc  );
        localStorage.setItem('subscriptions', JSON.stringify( permissions[indexPermission].subscriptions ) );
        localStorage.setItem('active_permission', permissions[indexPermission].id );
    }

}

export const subscriptionModule = (module,option) => {
    
  let found = false;
    
  if (localStorage.active_permission && localStorage.active_permission > 0)  
  {        
        var permissions = JSON.parse(localStorage.permissions);
        
        let indexPermission = 0;
        for (let i=0;i<permissions.length;i++)
        {
            if (permissions[i].id==localStorage.active_permission)
            {
                indexPermission = i;
            }                
        }
      
        if (permissions[indexPermission])
        {
          if (option == null)
          {
            for (let item of permissions[indexPermission].subscriptions) {
              if (item.modules.public_id === module) found = true;
            }
          }
          else
          {
            for (let item of permissions[indexPermission].subscriptions) {
              if (item.modules.public_id === module && item.modules_options.public_id === option) found = true;
            }
          }
        }
  }
    
  return found;
};

export const dateEventToCatalan = (dateStart,dateFinish,translate) => {
    
  const dateStartDate = new Date(dateStart);
  const dateFinishDate = new Date(dateFinish);
    
  const dataSenseHora1 = new Date(dateStartDate.getFullYear(), dateStartDate.getMonth(), dateStartDate.getDate());
  const dataSenseHora2 = new Date(dateFinishDate.getFullYear(), dateFinishDate.getMonth(), dateFinishDate.getDate());

  let strDate= '';
  if (dataSenseHora1 < dataSenseHora2 || dataSenseHora1 > dataSenseHora2 ) 
  {
    strDate=translate('common.date_from') + format(dateStartDate, 'EEEE, dd \'de\' MMMM \'de\' yyyy', { locale: ca }) + translate('common.date_to') + format(dateFinishDate, 'dd \'de\' MMMM', { locale: ca });
  } else {
    strDate=format(dateStartDate, 'EEEE, dd \'de\' MMMM \'de\' yyyy', { locale: ca }).replace('di','Di');
  }
    
  return strDate;
};

export const timeEventToCatalan = (dateStart,translate) => {
    
  const dateStartDate = new Date(dateStart);    
  return format(dateStartDate, '\'' + translate('common.time_to') + ' \'HH\':\'mm', { locale: ca });
};

export const dateOnlyToCatalan = (dateStart) => {
  
  if (dateStart)
  {
    const dateStartDate = new Date(dateStart);    
    return format(dateStartDate, 'dd/MM/yyyy', { locale: ca });
  }
  else
      return '';
};

export const dateAndTimeToCatalan = (dateStart) => {
  
  if (dateStart)
  {
    const dateStartDate = new Date(dateStart);    
    return format(dateStartDate, 'dd/MM/yyyy HH:mm', { locale: ca });
  }
  else
      return '';
};


export const structArrayToIdList = (arrayStruct) => {
    var arrayIds = [];
    for (var i = 0; i < arrayStruct.length; i++) {
      arrayIds.push(arrayStruct[i].id);
    }
    return arrayIds.join(',');
}


export const dateParser = value => {      
    if (value == null || value === '') return '';        
    return toIsoString(new Date(value));
}
    
export const toIsoString = date => {    
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
}

export const datesLastMonth = () => {
    
    // Obtener la fecha actual
    const today = new Date();
    
    // Obtener el primer día del mes pasado
    const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    
    // Obtener el último día del mes pasado
    const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    
    return {
        firstDayLastMonth: firstDayLastMonth,
        lastDayLastMonth: lastDayLastMonth
    };

}



export const LinearProgressWithLabel = props => {      
  let linearProgressValue = props.value > 100 ? 100 : props.value;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={linearProgressValue} className="donations_linear_progress" />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const RemainingTime = ({nSeconds, remainText, agoText}) => {  
    
    const translate = useTranslate();  
        
    let days = Math.floor(nSeconds / ( 60 * 60 * 24));
    let hours = Math.floor(nSeconds / (60*60));
    let minutes = Math.floor(nSeconds / (60));
               
    let retValue = '';
    let remain = translate('public_donations_projects.remain');
    
    if (nSeconds > 2*60*60*24)
        retValue = days + ' ' + translate('donations_projects.days');
    else if (nSeconds > 60*60*24)
    {
        retValue = days + ' ' + translate('donations_projects.day');
        remain = translate('public_donations_projects.remain_1');
    }
    else if (nSeconds > 2*60*60)
        retValue = hours + ' ' + translate('donations_projects.hours');
    else if (nSeconds > 60*60)
    {
        retValue = hours + ' ' + translate('donations_projects.hour');
        remain = translate('public_donations_projects.remain_1');
    }
    else if (nSeconds > 2*60)
        retValue = minutes + ' ' + translate('donations_projects.minutes');
    else if (nSeconds > 60)
    {
        retValue = minutes + ' ' + translate('donations_projects.minute');
        remain = translate('public_donations_projects.remain_1');
    }
    else if (nSeconds > 1)
        retValue = nSeconds + ' ' + translate('donations_projects.seconds');
    else if (nSeconds > 0)
    {
        retValue = nSeconds + ' ' + translate('donations_projects.second');
        remain = translate('public_donations_projects.remain_1');
    }
    
    if (remainText && retValue.length )
    {        
        return (<><span className="fs-14 grey8">{remain}</span><br/><span className="fs-20 bold">{retValue}</span></>);
    }
    if (agoText && retValue.length )
    {        
        return (<><span className="fs-14 grey8">{translate('public_donations_projects.ago')} {retValue}</span></>);
    }
    
    return (<span>{retValue}</span>);
    
}

export const DateTimeField = props => {      
  
  const translate = useTranslate();  
              
  return (
    <FunctionField render={record => dateAndTimeToCatalan(record[props.source], translate) } label={props.label}  sortable={false}  />
  );
}

export const DatagridNoResults = props => {
    
    const translate = useTranslate(); 
    
    return (<center><br />{translate('reports.no_results')}<br /><br /></center>);
}

export const formatFloat = (number) => {
    return number.toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
}


export const CopyToClipboard = ({ ...props }) => {
  
  const notify = useNotify();
  const translate = useTranslate();  
    
  const handleClick = (textToCopy) => {        
          
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        notify(translate('utils.copy_clipboard_success'), 'success');
      })
      .catch(err => {
        notify(translate('utils.copy_clipboard_error'), 'error');
      });
      
  };
  
  return (
       <Button onClick={handleClick.bind(this, props.message )} label={props.label} startIcon={<ContentCopyIcon />} />
  );
  
}