// in src/posts.js
import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  Datagrid,
  TextField,
  ReferenceInput,
  SelectInput,
  TextInput, 
  ReferenceManyField,
  useTranslate,
  DateField ,DateInput, required, useDataProvider, Loading, Error, FunctionField, useNotify, useCreate, useRefresh, Button, FormWithRedirect, BooleanInput, SaveButton
} from 'react-admin';

import { useFormState } from 'react-final-form';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';


import Grid from '@mui/material/Grid';
import IconCancel from '@material-ui/icons/Cancel';

import { SendEmailIconStatus } from './../components/Emails';
import MyLoading from '../components/MyLoading';
import { targetsEmails,modules } from './../common/constants';
import { subscriptionModule  } from '../common/utils';
import { DownloadCard,RegenerateCard } from '../resources/groups_cards';

import DoneIcon from '@mui/icons-material/Done';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import { validateMaxLength  } from './../components/Validator';


export const UsersGroupsShow= (props) => {
    
  const translate = useTranslate();  
            
  return (
    <>
      <ReferenceManyField                    
        reference="users_groups"
        target="users_id"
        label=""
        perPage={1000}
      >

        <Datagrid        
          className="users_remittances_list header_grey6"
        >
          <TextField source="group.name" label={translate('users.group')} />
          <TextField source="num_member" label={translate('users_groups.num_member')} />
                        
          { subscriptionModule(modules.RECERCAT) ?
            <DateField source="date_join" showTime label={translate('users_groups.date_join')}/>
            :
            <DateField source="date_join" label={translate('users_groups.date_join')}/> }
                
          { subscriptionModule(modules.RECERCAT) ?
            <DateField source="date_left" showTime label={translate('users_groups.date_left')}/>
            :
            <DateField source="date_left" label={translate('users_groups.date_left')}/>
          }
                        
          <TextField source="comment" label={translate('common.comment')} /> 
                        
          { !subscriptionModule(modules.RECERCAT) ?
            <FunctionField label={translate('users.welcome_email_sent')} render={record => 
              <SendEmailIconStatus record={record} target={targetsEmails.WELCOME}  />
            } sortable={false}  /> : null }
                        
          { subscriptionModule(modules.DIGITAL_CARDS) || subscriptionModule(modules.WALLETS) ?
            <FunctionField label={translate('users.digital_card_send')} render={record => 
              <>
                { record.group.card_digital ? <DownloadCard users_groups_id={record.id} /> : null }
                <SendEmailIconStatus record={record} target={targetsEmails.DIGITAL_CARD}  />
                { (record.group.card_digital || record.group.card_digital_wallets) && record.card_hash != null && record.card_hash.length > 0 ? <RegenerateCard users_groups_id={record.id} /> : null }              
              </>
            } sortable={false}  /> : null }
            
          { !subscriptionModule(modules.RECERCAT) ?
            <FunctionField label={translate('users.group_left_email_sent')} render={record => 
              <SendEmailIconStatus record={record} target={targetsEmails.GROUP_LEFT}  />
            } sortable={false}  /> : null }
            
           { !subscriptionModule(modules.RECERCAT) ?
            <FunctionField label={translate('common.actions')} render={record => 
              record.date_left == null ? 
              <LeftGroupUserButton id={record.id} groups_public_id={record.group.public_id} />
              : null 
            } sortable={false}  /> : null }
            
        </Datagrid>

      </ReferenceManyField> 
                
    </>
  );
};
                  
export const UsersGroupsEdit= (props) => {
  const translate = useTranslate();
  return (
    <ReferenceManyField                    
      reference="users_groups"
      target="users_id"
      label=""
      perPage={1000}
    >

      <EditableDatagrid                       
        createForm={<GroupFormNew initialValues={{ users_id: props.id, comment: '', date_join: props.today }} />}
        editForm={<GroupForm />}
        label=""
        hasCreate
        fullWidth
      >
        <TextField source="group.name" label={translate('users.group')} sortable={false} />
        <TextField source="num_member" label={translate('users_groups.num_member')} sortable={false} />
        <DateField source="date_join" label={translate('users_groups.date_join')} sortable={false} />
        <DateField source="date_left" label={translate('users_groups.date_left')} sortable={false} />
        <TextField source="comment" label={translate('common.comment')} sortable={false} />   
      </EditableDatagrid>

    </ReferenceManyField> 
  );
};

const GroupForm= (props) => {
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    <RowForm {...props}  >
      <ReferenceInput label={translate('users.group')} source="groups_id" reference="groups" filter={{ associations_id : localStorage.getItem('associations_id') }} perPage={1000} >
        <SelectInput fullWidth optionText="path" optionValue="id" validate={required()} disabled />
      </ReferenceInput>         
      <TextInput fullWidth source="num_member" label={translate('users_groups.num_member')} validate={[validateMaxLength(25,translate('common.validation.maxLength'))]} />   
      <DateInput fullWidth source="date_join" label={translate('users_groups.date_join')} />
      <DateInput fullWidth source="date_left" label={translate('users_groups.date_left')} />
      <TextInput fullWidth multiline source="comment" label={translate('common.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />   
    </RowForm>
  );
};


/* <LastNumMemberInserted groups_id={values.groups_id} /> */

const GroupFormNew= (props) => {
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    <RowForm {...props}  >
      <><br/>
        <ReferenceInput label={translate('users.group')} source="groups_id" reference="groups" filter={{ associations_id : localStorage.getItem('associations_id') }} perPage={1000} >            
          <SelectInput fullWidth optionText="path" optionValue="id" validate={required()} />            
        </ReferenceInput> 
      </>
      <NumMemberInput source="num_member" />
      <><br/>
        <DateInput fullWidth source="date_join" label={translate('users_groups.date_join')} />
      </>
      <span />
      <><br/>
        <TextInput fullWidth multiline source="comment" label={translate('common.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />   
      </>
    </RowForm>
  );
};

export const NumMemberInput = props => {
  const translate = useTranslate();   
  const { values } = useFormState();
        
  return (
    values.groups_id ? 
      <>
        <LastNumMemberInserted groups_id={values.groups_id} />
        <TextInput fullWidth source="num_member" label={translate('users_groups.num_member')} validate={[validateMaxLength(25,translate('common.validation.maxLength'))]} /> 
      </>
      : ''
  );
};


export const LastNumMemberInserted = props => { 
    
  const translate = useTranslate();   
  const dataProvider = useDataProvider();
  const [group, setGroup] = useState(0);
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
    
  if (group !== props.groups_id)
    setGroup(props.groups_id); 
    
  useEffect(() => {
    //console.log('LastNumMemberInserted->useEffect');
    dataProvider.getOne('users_groups/last-num-member-inserted', { id: props.groups_id })
      .then(( { data } ) => {           
        setInfo(data);
        setLoading(false);
      })
      .catch(error => {                
        setLoading(false);                
      });
            
  }, [group]);

  if (loading) return <MyLoading />;
  if (error) return <Error />;
  if (!info) return null;
    
  return (
    <span fullWidth className="last_num_inserted grey8">{translate('users_groups.last_num_inserted')} {info.num_member}</span>
  );
};





export const LeftGroupUserButton = ({ id, groups_public_id, ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users_groups/left');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
    
  const today = new Date().toISOString().slice(0, 10);


  const handleClick = () => {        
    setShowDialog(true);        
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('users_groups.left')} className="" />
        
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users_groups.left')}
      >
        <DialogTitle>{translate('users_groups.left')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ id : id , date_left: today, left_send_email: groups_public_id == 'SOCIS' ? true : false }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>     
                    <br />
                    <DateInput fullWidth source="date_left" label={translate('users_groups.date_left')} />
                  </Grid> 
                  <Grid item xs={6}>                         
                  </Grid> 
                  { groups_public_id == 'SOCIS' ?
                  <Grid item xs={12}>     
                    <BooleanInput fullWidth source="left_send_email" label={translate('users_groups.left_send_email')} />
                  </Grid> : null }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
